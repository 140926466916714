.filter-container {
  background-color: rgba(255, 255, 255, 0.4);

  @apply absolute z-20 transform shadow-xl p-5 md:p-10  
  sm:shadow-none transition-all 
  sm:w-full sm:translate-y-full sm:bottom-0;
}

@media (max-width: 639px) {
  .filter-container {
    background-color: rgba(255, 255, 255, 1);
    top: 0;
    left: 0;
    @apply w-screen h-screen;
  }
}
