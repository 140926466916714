.team-section p,
.team-section ul {
  /* @apply font-light; */
}

.team-section h3,
h1,
h2,
h4,
h5,
h6 {
  @apply my-5 lg:my-9;
}

.team-section-content h1 {
  font-size: 2em;
}

.team-section-content h2 {
  font-size: 1.5em;
}

.team-section-content h3 {
  font-size: 1.17em;
}

.team-section-content h4 {
  font-size: 1em;
}

.team-section-content h5 {
  font-size: 0.83em;
}

.team-section-content h6 {
  font-size: 0.67em;
}

.team-section-content p {
  margin-bottom: 5px;
  margin-top: 5px;
}

.team-section-content b {
  font-weight: bold;
}

.team-section-content ul {
  padding-left: 50px;
}
