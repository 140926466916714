.open {
  line-height: 1;
  padding: 2em 0;
  color: black;
}

.closed {
  line-height: 0;
  padding: 0;
  color: transparent;
  height: 0;
}

.closed p {
}

.open p {
  @apply text-lg;
}
