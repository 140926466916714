select:required:invalid {
  @apply text-gray-500;
}

select {
  outline: none;
  appearance: none;
}

.custom-select {
  /* flex-grow: 1;
  flex-basis: 200px; */
}

.custom-select .css-1s2u09g-control,
.custom-select .css-1pahdxg-control {
  @apply px-3 py-[9px] shadow-md;
  border: none;
  border-radius: 0px;
}

.css-1okebmr-indicatorSeparator {
  display: none !important;
}

.custom-select .css-1pahdxg-control {
  border: none;
  /* box-shadow: none; */
}

.css-1pahdxg-control:hover {
  border-width: 0px;
}

.custom-select__option {
}

.custom-select__menu-list .custom-select__option:active {
  @apply bg-slate-200;
}

.custom-select__option:hover {
  @apply bg-slate-100;
}

.custom-select__menu-list .custom-select__option--is-selected:active {
  @apply bg-slate-300;
}
