@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  padding: 0;
  margin: 0;
  font-family: "Inter", serif;
  overflow-x: hidden;
}

input,
button,
select,
label {
  font-family: "Inter", serif;
}

.font-poppins {
  font-family: "Inter", serif;
}

.font-ibm {
  font-family: "IBM Plex Serif", serif;
}

button {
  border: none;
  outline: none;
}

button:active,
button:focus,
select :focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

#hero {
  /* background-image: url(./assets/images/header-bg.png),
    linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100vh;
  background-blend-mode: overlay;
}

/* .card-image {
	object-fit: contain;
} */

.header-text-container > *:first-child {
  @apply text-white text-3xl md:text-7xl font-semibold;
}

.header-text-container > *:last-child {
  @apply text-primary mt-6 md:mt-9 font-semibold text-3xl md:text-5xl;
}

.multi-content-paragraph-container p {
  @apply text-sm  md:text-lg  ml-auto font-light;
}

.single-content h2,
h1,
h3,
h4,
h5 {
  @apply mb-5 md:mb-9;
}

.single-content p,
.solutions-section li {
  @apply my-5;
}

.solutions-section li {
  @apply my-3;
}

.solutions-section {
}
.multi-content li,
.multi-content p {
  @apply my-2;
}

.banner-img img {
  width: 100%;
}

ul {
  list-style: unset;
}

.detail-page li,
.detail-page p {
  @apply md:text-lg text-sm !important;
}
