.card {
  /* max-width: 488px; */
  /* md:w-1/2 lg:w-1/3 max-w-md  */
  @apply hover:bg-white transition-all 
    hover:shadow-2xl transform lg:hover:scale-110 hover:z-[1] w-full
    shadow-xl bg-gray-50 p-7
    md:p-7 xl:p-10 lg:max-w-xs xl:max-w-sm  2xl:max-w-md;
  flex-grow: 1;
  flex-basis: 400px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 576px) {
  .card {
    flex-basis: 300px;
  }
}

.card-link-visible {
  @apply hidden lg:flex font-normal group-hover:hidden transition-colors px-4 pl-0 py-1 text-mainBlack items-center;
}

.card-link-hidden {
  @apply bg-primary focus:bg-primary2 flex lg:hidden group-hover:flex font-normal justify-center text-white w-full px-4 py-1 items-center;
}

.logo-container {
  @apply h-48 lg:h-72 mb-6;
}

.logo-container img {
  @apply object-contain;
}

.stack-logo {
}

.stack-logo img {
  @apply md:h-14 md:w-14 w-10 h-10 shadow-md rounded-full;
}
