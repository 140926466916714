[data-reach-combobox-popover] {
  @apply bg-transparent text-white border-2 border-white border-t-0;
  background-color: rgba(255, 255, 255, 0.2);
}

[data-reach-combobox-list] {
}

[data-reach-combobox-option] {
  @apply py-2 px-10 md:px-14;
  font-family: "Poppins", serif !important;
}

/* background-color: rgba(0, 0, 0, 0.3); */
[data-reach-combobox-option][aria-selected="true"] {
  background-color: rgba(0, 0, 0, 0.3);
}

[data-reach-combobox-option]:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
