@tailwind base;
@tailwind components;
@tailwind utilities;

*,
body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
